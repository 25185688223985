<template>
  <div class="view projects">
    <navbar show-brand />
    <main class="flex-container">
      <div class="content">
        <header>
          <h1>My Projects</h1>
          <button @click="createProject" :disabled="newProjectPending">
            <font-awesome-icon icon="plus-square" v-if="!newProjectPending"/>
            <spinner v-else />
          </button>
        </header>
        <div class="alerts" v-if="errors.size > 0">
          <div class="alert error" v-for="(error, index) in errors" :key="index">
            {{ error }}
            <button @click="errors.delete(error)" class="close"><font-awesome-icon icon="times" /></button>
          </div>
        </div>
        <project-list @deleteProject="deleteProject" @createProject="createProject" />
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import ProjectList from '@/components/ProjectList.vue'
import Spinner from '@/components/Spinner.vue'

import { createProject, deleteProject } from '@/firebase/projects'

export default {
  name: 'Projects',
  components: { Navbar, ProjectList, Spinner },
  data () {
    return {
      newProjectPending: false,
      errors: new Set(),
    }
  },
  methods: {
    createProject () {
      this.newProjectPending = true
      createProject()
        .then(docRef => {
          this.newProjectPending = false
          this.$router.push(`/edit/${docRef.id}`)
        })
        .catch(err => {
          this.newProjectPending = false
          console.error(err)
          this.errors.add(err.message)
        })
    },
    deleteProject (id) {
      deleteProject(id)
        .catch(err => {
          console.error(err)
          this.errors.add(err.message)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.projects {
  header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    button {
      border: none;
      padding: 0.5rem;
      margin-left: 2rem;
      font-size: 40px;
      line-height: 1;
      height: calc(40px + 1rem);

      &:hover {
        color: var(--c-highlight);
        background: var(--c-bg-shade);
      }
    }
  }

  .alerts {
    margin-bottom: 1rem;
  }
}
</style>
