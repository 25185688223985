import { firebase, db, auth } from '.'

export async function createProject () {
  const user = auth.currentUser
  if (!user) throw new Error('You must be signed in to create a new project.')
  const uid = user.uid
  return db.collection('projects').add({
    name: 'New Project',
    description: '',
    owner: uid,
    lastEdited: firebase.firestore.FieldValue.serverTimestamp(),
  })
}

export async function deleteProject (projectID) {
  if (!auth.currentUser) throw new Error('You must be signed in to delete a project.')
  const docRef = db.collection('projects').doc(projectID)
  const project = await docRef.get()
  if (!project.exists) throw new Error('A project does not exist with the given ID.')
  return docRef.delete()
}
