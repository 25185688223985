<template>
  <div>
    <table class="project-list" v-if="projects.length">
      <thead>
        <tr>
          <th>
            Name
            <button @click="setSortKey('name')" :class="{ active: sortKey === 'name' }">
              <font-awesome-icon icon="sort"  v-if="sortKey !== 'name'"/>
              <font-awesome-icon icon="sort-up" v-else-if="sortDir === -1" />
              <font-awesome-icon icon="sort-down" v-else />
            </button>
          </th>
          <th>
            Last Edited
            <button @click="setSortKey('lastEdited')" :class="{ active: sortKey === 'lastEdited' }">
              <font-awesome-icon icon="sort"  v-if="sortKey !== 'lastEdited'"/>
              <font-awesome-icon icon="sort-up" v-else-if="sortDir === -1" />
              <font-awesome-icon icon="sort-down" v-else />
            </button>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="project in sorted" :key="project.id" :class="{ deleted: deleted.has(project.id) }">
          <td class="name">{{ project.name }}</td>
          <td class="last-edited">
            <span class="date">{{ project.lastEdited.toDate().toLocaleDateString() }}</span>
            <span class="time">, {{ project.lastEdited.toDate().toLocaleTimeString() }}</span>
          </td>
          <td class="controls">
            <button class="error delete" @click="deleteProject(project.id)" :disabled="deleted.has(project.id)">
              <font-awesome-icon icon="trash-alt" /> <span class="label">Delete</span>
            </button>
            <router-link class="btn primary edit" :to="`/edit/${project.id}/details`">
              <font-awesome-icon icon="edit" /> <span class="label">Edit</span>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <div class="alert no-projects">
        <strong>No projects to show!</strong>
        <button class="primary" @click="$emit('createProject')">Create a new project</button></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectList',
  data () {
    return {
      sortKey: 'lastEdited',
      sortDir: 1,
      deleted: new Set(),
    }
  },
  computed: {
    projects () {
      const obj = this.$store.state.userDoc?.projects ?? {}
      return Object.entries(obj).map(([key, value]) => {
        const { name, lastEdited } = value
        return {
          id: key,
          name,
          lastEdited,
        }
      })
    },
    sorted () {
      return Array.from(this.projects)
        .sort(this.compareProjects)
    },
  },
  methods: {
    timestampToLocalDate (timestamp) {
      const date = timestamp.toDate()
      return date.toLocaleString()
    },
    compareProjects (A, B) {
      const a = A[this.sortKey]
      const b = B[this.sortKey]
      if (a < b) return -1 * this.sortDir
      if (a > b) return 1 * this.sortDir
      return 0
    },
    setSortKey (key) {
      if (this.sortKey !== key) {
        this.sortKey = key
        this.sortDir = 1
      } else {
        this.sortDir *= -1
      }
    },
    deleteProject (id) {
      this.deleted.add(id)
      this.$emit('deleteProject', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.project-list {
  width: calc(100% + 2rem);
  border-spacing: 0 0.5rem;
  margin: 0 -1rem;

  tr {
    background: var(--c-bg-shade);
    border-bottom: 1px solid var(--c-border);

    &.deleted {
      filter: grayscale(50%) brightness(120%);
    }
  }

  th {
    text-align: left;
    border-bottom: 2px solid var(--c-border);

    button {
      border: none;
      background: transparent;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      position: relative;
      top: 2px;

      &:hover {
        color: var(--c-txt-emphasis);
        background: var(--c-bg);
      }

      &.active {
        color: var(--c-highlight);
      }

      svg {
        position: relative;
        top: -3px;
      }
    }
  }

  td, th {
    padding: 0.25rem;
    white-space: nowrap;

    &:first-child {
      padding-left: 0.5rem;
    }

    @include sm {
      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        padding-right: 1.5rem;
      }
    }
  }

  .name {
    font-weight: 600;
    color: var(--c-txt-emphasis);
  }

  .last-edited {
    font-weight: 300;

    .time {
      display: none;

      @include md {
        display: inline;
      }
    }
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.25rem;

    a, button {
      width: 3rem;
      text-align: center;

      .label {
        display: none;
      }
    }

    @include sm {
      gap: 0.5rem;

      a, button {
        width: auto;

        .label {
          display: inline-block;
        }
      }
    }
  }
}

.no-projects {
  display: flex;
  align-items: center;

  strong {
    display: block;
    font-size: 20px;
  }

  button {
    display: block;
    margin-left: auto;
  }
}
</style>
